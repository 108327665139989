import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTeamsDetailsSlice, SearchCampaignListSlice } from "../redux/teamSlice/team.slice";
import { Typography, Button, Box, TextField, InputAdornment, Autocomplete } from "@mui/material";
import CampaignTable from "../components/table";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";
import CustomBreadcrumb from "../components/CustomBreadcrumb/CustomBreadcrumb";
import Grid from "@mui/material/Grid2";
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#e6e6e6",
      borderRadius: "10px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },

  selectField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      // border: "1px solid #C5C5C5",
      backgroundColor: "#e6e6e6",
    },
    "& .MuiInputBase-input::placeholder": {
      backgroundColor: "#e6e6e6",
    },
  },

  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#e6e6e6",
      borderRadius: "10px",
      border: "0px solid #C5C5C5",
      background: "#FFF",
    },
    "& .MuiInputBase-input::placeholder": {
      backgroundColor: "#e6e6e6",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
}));


const dropdownOptions = {
  campaignType: [
    { label: "Lead Generation", value: "LEADGENERATION" },
    { label: "Brand Awareness", value: "BRANDAWARENESS" },
    { label: "Website Traffic", value: "WEBSITETRAFFIC" },
    { label: "Sales", value: "SALES" },
  ],
  channel: [
    { label: "SMS", value: "SMS" },
    { label: "WhatsApp", value: "WHATSAPP" },
  ],
  action: [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ],
  status: [
    { label: "Draft", value: "DRAFT" },
    { label: "Pending", value: "Pending" },
    { label: "Rejected", value: "Rejected" },
    { label: "Approved", value: "Approved" },
    { label: "Executed", value: "Executed" },
  ],
};



const CampaignList = () => {
  const classes = useStyles();
  const breadcrumb = [
    {
      title: 'Home',
      href: 'dashboard',
    },
    {
      title: 'List Of Campaigns',
      href: 'campaign-list',
    }
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { campaigns, totalItems, loading } = useSelector(
    (state) => state.teamsDetails
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [campaignName, setCampaignName] = useState('');
  const [filters, setFilters] = useState({
    campaignType: null,
    channel: null,
    action: null,
    status: null,
  });



  // Fetch campaigns when page or rowsPerPage change
  useEffect(() => {
    dispatch(fetchTeamsDetailsSlice({ page, rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  // Handle page change in table
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setCampaignName(event.target.value)

    var payload = {
      data: [
        {
          campaignName: event.target.value ? event.target.value : '',
        },
        {
          campaignType: filters?.campaignType?.value ? filters?.campaignType?.value : '',
        },
        {
          active: filters?.action?.value ? filters?.action?.value : '',
        },
        {
          status: filters?.status?.value ? filters?.status?.value : '',
        },
        {
          channel: filters?.channel?.value ? filters?.channel?.value : '',
        },
      ],
      page: page || 0,
      size: rowsPerPage || 10
    }

    setCampaignName(event.target.value);

    dispatch(SearchCampaignListSlice(payload))
  }

  const handleDropdownChange = (field) => (event, newValue) => {
    const updatedValue = { [field]: newValue };

    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedValue,
    }));

    const payload = {
      data: [
        {
          campaignName: campaignName || "",
        },
        {
          campaignType:
            field === "campaignType" ? newValue?.value || "" : filters?.campaignType?.value || "",
        },
        {
          active: field === "action" ? newValue?.value || "" : filters?.action?.value || "",
        },
        {
          status: field === "status" ? newValue?.value || "" : filters?.status?.value || "",
        },
        {
          channel: field === "channel" ? newValue?.value || "" : filters?.channel?.value || "",
        },
      ],
      page: page || 0,
      size: rowsPerPage || 10,
    };

    dispatch(SearchCampaignListSlice(payload));
  };



  return (
    <Box>
      <CustomBreadcrumb breadcrumb={breadcrumb} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "16px",
        }}
      >
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: "40px",
            // padding: "15px 30px",
            color: "#445A68",
            fontSize: "18px",
            fontWeight: "600",
            border: "1px solid #445A68",
          }}
          onClick={() => {
            navigate("/create-campaign");
          }}
        >
          New Campaign
        </Button>
        <Button
          disabled
          variant="contained"
          endIcon={<SortIcon />}
          sx={{
            color: "#fff",
            backgroundColor: "#13BECF",
            borderRadius: "10px",
            fontSize: "16px",
            fontWeight: "500",
            borderRadius: "6px",
            // border: "1px solid #13BECF",
            cursor: "not-allowed ",
          }}
        >
          Sort
        </Button>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '16px' }}>
        <Typography sx={{ color: '#445A68', fontSize: '29px', fontWeight: '600' }}>Campaign List</Typography>

        <Typography sx={{
          fontSize: '19px',
          fontWeight: '600',
          background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
          WebkitBackgroundClip: 'text',
          color: 'transparent',
        }}>
          {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, totalItems)} <span style={{ color: '#455967' }}>of {totalItems}</span>
        </Typography>
      </Box>

      <Grid container mt={3} spacing={2}>
        <Grid size={{ md: 4 }}>
          <TextField
            variant="outlined"
            placeholder="Search By Category Name"
            fullWidth
            className={classes.searchBar}
            sx={{ color: "#ECECEC" }}
            value={campaignName}
            onChange={e => handleSearchChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {Object.entries(dropdownOptions).map(([key, options]) => (
          <Grid key={key} size={{ md: 2 }}>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option.label}
              value={filters[key]}
              onChange={handleDropdownChange(key)}d
              className={classes.selectField}
              renderInput={(params) => (
                <TextField {...params} className={classes.textField} placeholder={`Search By ${key}`} />
              )}
            />
          </Grid>
        ))}
      </Grid>

      <CampaignTable
        campaigns={campaigns}
        totalItems={totalItems}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Box>
  );
};

export default CampaignList;
